<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3 class="alert_title">최신 버전의 앱이 있습니다</h3>
            <p class="alert_body">
                최적의 사용 환경을 위해 사용성을 개선했습니다.<br>
                지금 바로 최신 버전의 앱으로 업데이트 해주세요.
            </p>
            <div class="single-file-btn">
                <p id="color-text" @click="goStore()">업데이트</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'UpdateModal',
  data () {
    return {
      desc: ``
    };
  },
  methods: {
    goStore () {
      location.href = `https://play.google.com/store/apps/details?id=com.mobileaos.aplayz`;
    }
  }
};
</script>
<style src="@/assets/css/content2.css"></style>
<style scoped>
.alert_title {
  padding-bottom: 0;
  margin-bottom: 0;
  font-weight: 500;
}

.alert_body {
  font-size: 16px;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 100;
  color: rgba(255, 255, 255, 0.87);
  padding-top: 10px;
  word-break: keep-all;
}
</style>
