<template>
    <div>
        <UpdateModal v-if="isUpdateModal" />
        <router-view />
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import axios from '@/service/axios';
import UpdateModal from '@/components/update/updateModal.vue';
export default defineComponent({
  name: 'Layout3',
  components: {
    UpdateModal
  },
  data () {
    return {
      isUpdateModal: false
    };
  },
  methods: {
    appleReissue (refreshToken, state) {
      axios
        .post('/api/social/reissueAppleOAuthToken', { refreshToken, state })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            this.$cookies.set('socialAToken', result.access_token);
            this.appleAutoLogin(result.id_token);
          }
        })
        .catch((err) => {
          console.log(err);
          alert('애플 로그인에 실패하였습니다.');
          this.$router.replace({ name: 'Login' });
        });
    },
    appleAutoLogin (identityToken) {
      const accessToken = this.$cookies.get('socialAToken');
      const email = this.$cookies.get('email');

      axios
        .post('/api/social/appleCallback', { accessToken, email, identityToken })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            this.$cookies.set('userId', result.userId);
            if (result.accessToken !== null && result.accessToken !== undefined) {
              console.log('애플 로그인, 토큰 발급 성공');
              // res 값으로 토큰이 있다면 로그인
              this.$cookies.set('aToken', result.accessToken);
              this.$cookies.set('rToken', result.refreshToken);
              this.$cookies.set('email', result.email);

              this.loginAuto();
            }
          }
        })
        .catch((err) => {
          console.log(err);
          alert('애플 로그인에 실패하였습니다.');
          this.$router.replace({ name: 'Login' });
        });
    },
    naverReissue (refreshToken) {
      axios
        .post('/api/social/reissueNaverOAuthToken', { refreshToken })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            // console.log("네이버 토큰 갱신 성공");
            this.$cookies.set('socialAToken', result.access_token, result.expires_in);
            this.naverAutoLogin('naverReissue');
          }
        })
        .catch((err) => {
          console.log(err);
          // console.log("네이버 토큰 갱신 오류");
          alert('네이버 로그인에 실패하였습니다.');
          this.$router.replace({ name: 'Login' });
        });
    },
    naverAutoLogin (reissue) {
      // console.log('naver 자동로그인');
      const accessToken = this.$cookies.get('socialAToken');
      const email = this.$cookies.get('email');

      axios
        .post('/api/social/naverCallback', { accessToken, email })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            this.$cookies.set('userId', result.userId);
            if (result.accessToken !== null && result.accessToken !== undefined) {
              // console.log('네이버 로그인, 토큰 발급 성공');
              // res 값으로 토큰이 있다면 로그인
              this.$cookies.set('aToken', result.accessToken);
              this.$cookies.set('rToken', result.refreshToken);
              this.$cookies.set('email', result.email);

              this.loginAuto();
            }
          }
        })
        .catch((err) => {
          console.log(err);
          const response = err.response;
          if (response.data.resultCd === '500' && reissue !== 'naverReissue') {
            // console.log('네이버 엑세스 토큰 갱신');
            this.naverReissue(this.$cookies.get('socialRToken'));
          } else {
            // alert("네이버 로그인에 실패하였습니다.");
            this.$router.replace({ name: 'Login' });
          }
        });
    },
    kakaoReissue (socialRToken) {
      const url = window.location.href;
      const crientId = url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0 ? process.env.VUE_APP_DEV_KAKAO_CRIENT_ID : process.env.VUE_APP_PRO_KAKAO_CRIENT_ID;
      const instance = axios.create();
      const makeFormData = (params) => {
        const searchParams = new URLSearchParams();
        Object.keys(params).forEach((key) => {
          searchParams.append(key, params[key]);
        });
        return searchParams;
      };

      instance({
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: 'https://kauth.kakao.com/oauth/token',
        data: makeFormData({
          grant_type: 'refresh_token',
          client_id: crientId,
          refresh_token: socialRToken
        })
      })
        .then((res) => {
          if (res.status === 200) {
            // console.log("카카오 토큰갱신 성공");
            console.log(res);
            const data = res.data;
            this.$cookies.set('socialAToken', data.access_token, data.expires_in);
            if (data.refresh_token !== null || data.refresh_token !== undefined) this.$cookies.set('socialRToken', data.refresh_token, data.refresh_token_expires_in);
            this.kakaoAutoLogin('kakaoReissue');
          }
        })
        .catch((err) => {
          // console.log("카카오 토큰갱신 오류");
          console.log(err);
          // alert("카카오 로그인에 실패하였습니다.");
          this.$router.replace({ name: 'Login' });
        });
    },
    kakaoAutoLogin (reissue) {
      // console.log('kakao 자동로그인');
      const accessToken = this.$cookies.get('socialAToken');
      const email = this.$cookies.get('email');

      axios
        .post('/api/social/kakaoCallback', { accessToken, email })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            this.$cookies.set('userId', result.userId);
            if (result.accessToken !== null && result.accessToken !== undefined) {
              // console.log('카카오 로그인, 토큰 발급 성공');
              // res 값으로 토큰이 있다면 로그인
              this.$cookies.set('aToken', result.accessToken, '30d');
              this.$cookies.set('rToken', result.refreshToken, '30d');
              this.$cookies.set('email', result.email, '30d');

              this.loginAuto();
            }
          }
        })
        .catch((err) => {
          console.log(err);
          const response = err.response;
          if (response.data.resultCd === '500' && reissue !== 'kakaoReissue') {
            // console.log('카카오 엑세스 토큰 갱신');
            this.kakaoReissue(this.$cookies.get('socialRToken'));
          } else {
            alert('카카오 로그인에 실패하였습니다.');
            this.$router.replace({ name: 'Login' });
          }
        });
    },
    loginAuto () {
      const email = this.$cookies.get('email');
      const comeFrom = this.$cookies.get('comeFrom');
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/user/mailAcceptCheck', { email, comeFrom }, { headers })
        .then((res) => {
          if (res.data.result) {
            // 이메일 인증 완료
            axios
              .post('/api/user/getLoginUser', { email }, { headers })
              .then((response) => {
                var userId = response.data.result.userId;
                var email = response.data.result.email;
                var comeFrom = response.data.result.comeFrom;
                var userType = response.data.result.userType;
                var signupCouponAt = response.data.result.signupCouponAt;
                var division = response.data.result.division;
                // 쿠키 셋팅
                this.$cookies.set('userId', userId);
                this.$cookies.set('email', email);
                this.$cookies.set('comeFrom', comeFrom);
                this.$cookies.set('userType', userType);
                // 자동 로그인, 아이디 저장 기간 연장
                this.$cookies.set('autoLogin', 'Y', 10 * 365 * 24 * 60 * 60 * 1000);
                this.$cookies.set('saveId', this.$cookies.get('saveId'), 10 * 365 * 24 * 60 * 60 * 1000);

                this.$store.commit('setEmail', email);
                this.$store.commit('setSignupCouponAt', signupCouponAt);
                this.$store.commit('setDivision', division);
                this.$store.commit('setRoute', response.data.route ? response.data.route : null);

                var spaceId = this.$cookies.get('spaceId');
                var aToken = this.$cookies.get('aToken');
                var headers = { 'X-AUTH-TOKEN': aToken };
                // 페이지 이동
                if (spaceId != null) {
                  // 쿠키에 spaceId가 있을때
                  axios
                    .post('/api/profile/getMySpaceInfo', { spaceId }, { headers })
                    .then((res2) => {
                      if (res2.data.result.spacePayAt === 'Y' || res2.data.result.spacePayAt === 'T') {
                        // 구독중
                        this.$router.replace({ name: 'Player', params: { spaceId: spaceId } });
                      } else {
                        // 미구독중
                        axios
                          .post('/api/profile/checkSpace', { userId }, { headers })
                          .then((res3) => {
                            if (res3.data.resultCd === '0000') {
                              const result = res3.data.result;
                              let sCount = -1;
                              if (result.length > 0) sCount = result.filter((e) => e === 'S').length;
                              if (sCount === -1 || sCount === result.length) {
                                // 보유 스페이스가 없거나 전부 S인 경우
                                this.$router.push({ name: 'SpaceInsert' });
                              } else {
                                this.$router.push({ name: 'MySpace' });
                              }
                            }
                          })
                          .catch((error3) => {
                            console.log(error3);
                          });
                      }
                    })
                    .catch((err2) => {
                      // spaceId가 사용중이 아닐 경우(에러코드 확인하여 로직추가 필요)
                      console.log(err2);
                    });
                } else {
                  // 쿠키에 spaceId가 없을때
                  axios
                    .post('/api/profile/subscribeSpaceList', { userId }, { headers })
                    .then((response2) => {
                      // console.log(response2);
                      if (response2.data.result.length > 1) {
                        // 구독중 spaceId가 2개 이상인 경우
                        this.$router.replace({ name: 'SpaceChoice', params: { spaceList: response2.data.result } });
                      } else if (response2.data.result.length === 1) {
                        // 구독중 spaceId가 1개인 경우
                        // console.log(response2.data.result);
                        this.$cookies.set('spaceId', response2.data.result[0].spaceId);
                        this.$router.replace({ name: 'Player' });
                      }
                    })
                    .catch((error2) => {
                      // console.log(error2);
                      var resultCd = error2.response.data.resultCd;
                      if (resultCd === 'D0001') {
                        // 구독중인 스페이스가 없는 경우
                        axios
                          .post('/api/profile/checkSpace', { userId }, { headers })
                          .then((response3) => {
                            if (response3.data.resultCd === '0000') {
                              const result = response3.data.result;
                              let sCount = -1;
                              if (result.length > 0) sCount = result.filter((e) => e === 'S').length;
                              if (sCount === -1 || sCount === result.length) {
                                // 보유 스페이스가 없거나 전부 S인 경우
                                this.$router.push({ name: 'SpaceInsert' });
                              } else {
                                this.$router.push({ name: 'MySpace' });
                              }
                            }
                          })
                          .catch((error3) => {
                            console.log(error3);
                          });
                      }
                    });
                }
              })
              .catch((error) => {
                console.log(error);
                this.$cookies.keys().forEach((cookie) => this.$cookies.remove(cookie));
                this.$store.commit('initData');
                alert('로그인 중 오류가 발생하였습니다. 다시 로그인을 시도해주세요.');
                this.$router.replace({ name: 'Login' });
              });
          } else {
            // 이메일 인증 미완료
            this.ShowResendEmail = true;
          }
        })
        .catch((error) => {
          console.error('user/mailAcceptCheck error : ', error);
        });
    },
    setTutorial () {
      localStorage.setItem('tutorialStatusMain', 'Y');
      localStorage.setItem('tutorialStatus', 'Y');
      localStorage.setItem('tutorialStatusBrand', 'Y');
      localStorage.setItem('tutorialStatusBrand2', 'Y');
    }
  },
  beforeMount () {
    const comeFrom = this.$cookies.get('comeFrom');
    if ((comeFrom === 'naver' || comeFrom === 'kakao' || comeFrom === 'apple') && this.$cookies.get('socialAToken') !== null) {
      comeFrom === 'kakao' ? this.kakaoAutoLogin() : comeFrom === 'naver' ? this.naverAutoLogin() : this.appleReissue(this.$cookies.get('socialRToken'), 'web');
    } else {
      this.$cookies.get('autoLogin') === 'Y' ? this.loginAuto() : this.setTutorial();
    }
  }
});
</script>
